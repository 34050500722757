.logo {
    display: flex;
    width: 140px;
    height: 140px;
    padding: 20px;

    flex-direction: column;
    justify-content: center;
    
    border-radius: 8px;
    background: $white;
    margin: 12px;

    @include media-breakpoint-up(lg) {
        margin: 24px;
    }
}