// Color system: https://codepen.io/migli/pen/QaGNVp/

$white: #fff !default;
$gray-50: #f9f9fa !default;
$gray-75: #f6f6f7 !default;
$gray-100: #f3f3f4 !default;
$gray-130: #f2f4f5 !default;
$gray-140: #EFEFEF !default;
$gray-150: #e7e8e8 !default;
$gray-200: #e2e2e4 !default;
$gray-220: #d2d2d4 !default;
$gray-250: #cecece !default;
$gray-300: #c5c5c9 !default;
$gray-350: #b5b5b5 !default;
$gray-400: #a7a7ad !default;
$gray-450: #afafB4 !default;
$gray-500: #8a8a92 !default;
$gray-600: #68686e !default;
$gray-700: #414141 !default;
$gray-800: #232325 !default;
$gray-900: #151516 !default;
$black: #000 !default;

$dark-blue: #0c6abb !default;
$blue: #0C6ABB !default;
$light-blue: #D6EAF8 !default;
$lighter-blue: #f2f8fc !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #E74C3C !default;
$dark-red: #C91A08 !default;
$orange: #F39021 !default;
$yellow: #F39C12 !default;
$green: #30B83E !default;
$teal: #20c997 !default;
$cyan: #3498DB !default;

$lighter-orange: #FFF9F3 !default;
$lighter-green: #F5FFF7 !default;

$primary: #005393 !default;
$bright-primary: #0C6ABB !default;
$light-primary: $light-blue !default;
$lighter-primary: $lighter-blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $gray-200 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-150 !default;
$dark: $gray-700 !default;
$visited: $gray-500 !default;
$muted: $gray-500 !default;

$theme-colors: ("white": $white) !default;

$yiq-contrasted-threshold: 175 !default;

/* set the overriding variables */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

// Spacing
$spacer: 1rem !default;
$spacers: (
    5: ($spacer * 2)
) !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: $white !default;
$body-color: $gray-700 !default;

// Links
$link-color: $bright-primary !default;
$link-color-product: $link-color !default;
$link-secondary: $gray-700 !default;
$link-secondary-hover: darken($link-secondary, 15%) !default;
$link-muted: $muted !default;
$link-muted-hover: darken($link-muted, 15%) !default;

// Fonts
$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
$enable-responsive-font-sizes: true !default;
$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-md: $font-size-base * .9 !default;
$font-size-sm: $font-size-base * .875 !default;
$font-size-xs: $font-size-base * .8 !default;
$line-height-base: 1.6 !default;
$line-height-lg: 1.6 !default;
$line-height-md: 1.6 !default;
$line-height-sm: 1.6 !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semi-bold:       700 !default;
$font-weight-bold:            700 !default;

$lead-font-size:              $font-size-base * 1.125 !default;
$lead-font-weight:            $font-weight-normal !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: 1rem !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$headings-font-weight: 700 !default;

$display1-size: $font-size-base * 3.5 !default;
$display1-weight: $headings-font-weight !default;
$display2-size: $font-size-base * 2.5 !default;
$display2-weight: $headings-font-weight !default;
$display3-size: $font-size-base * 1.75 !default;
$display3-weight: $headings-font-weight !default;

$text-muted: $muted !default;
$text-muted-light: $gray-300 !default;

// borders
$border-color: $gray-300 !default;
$border-color-light: $gray-200 !default;
$border-color-dark: $gray-500 !default;

// hr
$hr-margin-y: $spacer !default;

// Tables
$table-accent-bg: $gray-50 !default;
$table-head-bg: $gray-100 !default;
$table-head-color: $body-color !default;
$table-total-bg: $gray-100 !default;
$table-cell-padding-md: .75rem !default;

// Forms
$input-bg: $white !default;
$component-active-bg: $primary !default;
//$input-border-color:          $input-bg !default;
$input-border-color-dark: $gray-400 !default;
$input-solid-bg: $white !default;
$input-btn-focus-width: 0 !default;
$input-placeholder-color: $gray-300;

$custom-select-bg-size: 8px 8px !default;
$custom-select-indicator-padding: .75rem !default;
$custom-select-indicator-color: $primary !default;
$custom-select-indicator: str-replace(url("data:image/svg+xml,%3Csvg width='11px' height='6px' viewBox='0 0 11 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='ic_arrow_down' fill='#{$custom-select-indicator-color}' stroke='none' stroke-width='1' fill-rule='evenodd'%3E%3Cpath d='M6.93933978,3.00000002 L2.46966986,7.46966993 C2.17677664,7.76256315 2.17677664,8.23743689 2.46966986,8.53033011 C2.76256308,8.82322333 3.23743681,8.82322333 3.53033003,8.53033011 L8.53033003,3.53033011 C8.82322325,3.23743689 8.82322325,2.76256315 8.53033003,2.46966993 L3.53033003,-2.53033007 C3.23743681,-2.82322328 2.76256308,-2.82322328 2.46966986,-2.53033007 C2.17677664,-2.23743685 2.17677664,-1.76256311 2.46966986,-1.46966989 L6.93933978,3.00000002 Z' id='Path' transform='translate(5.500000, 3.000000) rotate(-270.000000) translate(-5.500000, -3.000000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-indicator-dark: str-replace(url("data:image/svg+xml,%3Csvg width='11px' height='6px' viewBox='0 0 11 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='ic_arrow_down' fill='#{$body-color}' stroke='none' stroke-width='1' fill-rule='evenodd'%3E%3Cpath d='M6.93933978,3.00000002 L2.46966986,7.46966993 C2.17677664,7.76256315 2.17677664,8.23743689 2.46966986,8.53033011 C2.76256308,8.82322333 3.23743681,8.82322333 3.53033003,8.53033011 L8.53033003,3.53033011 C8.82322325,3.23743689 8.82322325,2.76256315 8.53033003,2.46966993 L3.53033003,-2.53033007 C3.23743681,-2.82322328 2.76256308,-2.82322328 2.46966986,-2.53033007 C2.17677664,-2.23743685 2.17677664,-1.76256311 2.46966986,-1.46966989 L6.93933978,3.00000002 Z' id='Path' transform='translate(5.500000, 3.000000) rotate(-270.000000) translate(-5.500000, -3.000000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-indicator-white: str-replace(url("data:image/svg+xml,%3Csvg width='11px' height='6px' viewBox='0 0 11 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='ic_arrow_down' fill='#{$white}' stroke='none' stroke-width='1' fill-rule='evenodd'%3E%3Cpath d='M6.93933978,3.00000002 L2.46966986,7.46966993 C2.17677664,7.76256315 2.17677664,8.23743689 2.46966986,8.53033011 C2.76256308,8.82322333 3.23743681,8.82322333 3.53033003,8.53033011 L8.53033003,3.53033011 C8.82322325,3.23743689 8.82322325,2.76256315 8.53033003,2.46966993 L3.53033003,-2.53033007 C3.23743681,-2.82322328 2.76256308,-2.82322328 2.46966986,-2.53033007 C2.17677664,-2.23743685 2.17677664,-1.76256311 2.46966986,-1.46966989 L6.93933978,3.00000002 Z' id='Path' transform='translate(5.500000, 3.000000) rotate(-270.000000) translate(-5.500000, -3.000000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E"), "#", "%23") !default;


// Buttons
$input-btn-padding-y: .4rem !default;
$input-btn-padding-y-lg: .7rem !default;
$input-btn-padding-y-sm: .2rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-lg: $font-size-base !default;
$input-btn-font-size-sm: $font-size-md !default;
$input-btn-line-height-sm: $line-height-md !default;
$product-actions-btn-color: $bright-primary !default;
$btn-transition: visibility .3s ease-in-out, opacity .3s ease-in-out, color .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Dropdowns
$dropdown-border-color: $border-color-light !default;
$dropdown-link-color: $link-color !default;
$dropdown-link-hover-color: darken($link-color, 15%) !default;
$cart-dropdown-product-link-color: $primary !default;
$dropdown-link-hover-bg: $white !default;

// Navs
$nav-main-color: $bright-primary !default;
$nav-main-weight: 600 !default;
$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 2rem !default;
$nav-link-padding-x-sm: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;
$nav-tabs-border-color: $gray-200 !default;
$nav-tabs-link-active-color: $body-color !default;
$nav-pills-link-active-bg: none !default;
$nav-pills-link-active-color: $body-color !default;
$nav-pills-border-radius: 0 !default;
$nav-pills-active-link-color:   #000000 !default;
$nav-pills-active-border-color: $bright-primary !default;
$nav-pills-link-color: $bright-primary !default;

// Navbar
$navbar-padding-y: 1rem !default;
$navbar-dark-color: $white !default;
$navbar-dark-hover-color: $success !default;

// Progress bars
$progress-height: 0.625rem !default;
$progress-font-size: 0.625rem !default;

// List group
$list-group-hover-bg: $gray-200 !default;
$list-group-disabled-bg: $gray-200 !default;

// Close
$close-color: $body-color !default;
$close-text-shadow: none !default;

// Card
$card-border-radius: 2px !default;
$card-border-width: 0 !default;
$card-inner-border-radius: 2px !default;
$card-border-color: $white !default;
$card-main-border-color: $primary !default;
$card-cap-bg: $white !default;
$card-spacer-y: 1rem !default;
$card-spacer-x: 1rem !default;
$card-spacer-x-md: 1.5rem;
$card-spacer-x-lg: 2rem;
$card-call-to-action-bg-color: #f0f8fe !default;

// Modal
$modal-md: 700px !default;
$modal-header-border-width: 0 !default;
$modal-content-border-width: 0 !default;
$modal-backdrop-bg: $gray-900 !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 0 $modal-header-padding-x !default;

$modal-header-padding-y-xl: 3rem !default;
$modal-header-padding-x-xl: $modal-header-padding-y-xl !default;
$modal-header-padding-xl: $modal-header-padding-y-xl $modal-header-padding-x-xl 0 $modal-header-padding-x-xl !default;
$modal-inner-padding-xl: $modal-header-padding-y $modal-header-padding-x-xl !default;
$modal-footer-padding-xl: 0 $modal-header-padding-x-xl $modal-header-padding-y-xl $modal-header-padding-x-xl !default;

// Badges
$badge-font-size: $font-size-sm !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 1em !default;
$badge-padding-x: 1em !default;
$badge-pill-padding-x: 1em !default;
$badge-cancelled: $danger;
$badge-partly-delivered: #C8AB20 !default;
$badge-border-radius: 2px !default;

// Jumbotron
$jumbotron-bg: $white !default;
