html { 
    scroll-behavior: smooth; 
}

body {
    margin-top: 70px;
}

.scroll-element {
    scroll-margin-top: 75px;
}

footer {
    background-color: #110E20;
}