.bg-blue {
    background: $blue !important;
}

.bg-blue-circles {
    background-color: $blue !important;
    background-image: url('data:image/svg+xml,<svg width="1440" height="572" viewBox="0 0 1440 572" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="1371.5" cy="6.39795" r="162" stroke="%23004E96" stroke-width="2"/><circle cx="1305.5" cy="84.3979" r="227.395" stroke="%23004E96" stroke-width="4.21101"/><circle cx="-0.5" cy="391.398" r="162" stroke="%23004E96" stroke-width="2"/><circle cx="39.5" cy="361.398" r="208.569" stroke="%23004E96" stroke-width="3.86239"/></svg>');
}

.bg-orange-gradient {
    background: #F0F9FE;
    background: linear-gradient(45deg, rgba(243,144,0,0.48503151260504207) 0%, rgba(243,144,0,0.12648809523809523) 15%, rgba(240, 249, 254, 1) 62%);
}

.bg-header {
    background-color: #F0F9FE;
}